import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { PopupContext } from '../Popup/Popupcontext';

function CollapsibleText(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currentLanguage } = useContext(PopupContext);
  const lineHeight = 1.6; 
  const initialHeight = `${lineHeight * 3}rem`; 

  function handleToggle() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className='relative'>
      <motion.div
        className={`overflow-hidden transition-all duration-300 ${isExpanded ? 'h-auto' : ''}`}
        style={{ height: isExpanded ? 'auto' : initialHeight }}
        initial={{ opacity: 1 }}
        animate={{ opacity: isExpanded ? 1 : 0.5 }}
        transition={{ duration: 0.5 }}
      >
        <p className='text-left text-bases leading-relaxed'>
          {props.text}
        </p>
      </motion.div>
      <motion.button
        onClick={handleToggle}
        className='mt-2 text-left px-4 py-2'
        whileTap={{ scale: 0.95 }} 
        whileHover={{ scale: 1.05 }} 
        transition={{ duration: 0.2 }}
      >
        {isExpanded ? currentLanguage.show_less : currentLanguage.show_more}
      </motion.button>
    </div>
  );
}

function TextScroll(props) {
  const { currentLanguage } = useContext(PopupContext);
  return (
    <motion.div className='flex md:flex-row justify-center p-10 gap-4 flex-col vsm:p-0 sm:p-0'>
      <motion.div className='flex flex-col mt-10 w-full max-w-md vsm:max-w-full'>
        <motion.p className='text-left py-5 text-gray-500 leading-tight'>01</motion.p>
        <div className='h-[0.5px] bg-gray-500 w-full mb-4'></div>
        <motion.h1 className='py-4 text-lg text-left font-bold leading-snug'>{currentLanguage.ten_heading}</motion.h1>
        <CollapsibleText
          text={currentLanguage.ten}
        />
      </motion.div>
      <motion.div className='flex flex-col mt-10 w-full max-w-md'>
        <p className='text-left py-5 text-gray-500 leading-tight'>02</p>
        <div className='h-[0.5px] bg-gray-500 w-full mb-4'></div>
        <h1 className='py-4 text-lg text-left font-bold leading-snug'>{currentLanguage.inter_heading}</h1>
        <CollapsibleText
          text={currentLanguage.inter}
        />
      </motion.div>
      <motion.div className='flex flex-col mt-10 w-full max-w-md'>
        <p className='text-left py-5 text-gray-500 leading-tight'>03</p>
        <div className='h-[0.5px] bg-gray-500 w-full mb-4'></div>
        <h1 className='py-4 text-lg text-left font-bold leading-snug'>{currentLanguage.Graduation}</h1>
        <CollapsibleText
          text={currentLanguage.degree}
        />
      </motion.div>
    </motion.div>
  );
}

export default TextScroll;