import { useInView, motion } from "framer-motion";
import { useRef } from "react";
import React from "react";
import { slideup } from "./anim";
import "./main.scss";
import { useContext } from "react";
import { PopupContext } from "../Popup/Popupcontext";

function Main_text() {
  const { currentLanguage } = useContext(PopupContext);
  const phrase = currentLanguage.secondpagetext;
  const description = useRef(null);
  const isInView = useInView(description);

  return (
    <div ref={description} className="description ">
      <div className="body">
        <p>
          {phrase.split(" ").map((word, index) => {
            return (
              <span className="mask ">
                <motion.span
                  key={index}
                  variants={slideup}
                  className={`inline-block  overflow-hidden transition duration-500
                         ${
                           isInView
                             ? "opacity-100 translate-y-0"
                             : "opacity-0 translate-y-10"
                         }`}
                  custom={index}
                  animate={isInView ? "open" : "closed"}
                >
                  {word}
                </motion.span>
              </span>
            );
          })}
        </p>
      </div>
    </div>
  );
}

export default Main_text;
