import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import Magnetic from "../Framermotion/Magentic";
import HoverNameChange from "./Namechager";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Navbar1 from "../Navbar_small";
import { PopupContext } from "../Popup/Popupcontext";
import { useMediaQuery } from 'react-responsive';

function Navbar() {
  const { currentLanguage } = useContext(PopupContext);
  const [backgroundColor, setBackgroundColor] = useState(
    localStorage.getItem("backgroundColor") || ""
  ); 
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [textColor, setTextColor] = useState(""); 
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    handleNavigation(location.pathname); 
  }, [location.pathname]);
  const handleNavigation = (page) => {
    switch (true) {
      case page.startsWith("/about"):
      case page.startsWith("/About"):
        setBackgroundColor("#C4C4C4");
        setTextColor("");
        break;
      case page.startsWith("/Projects/detail/linearregression"):
        setBackgroundColor("#FFFF");
        setTextColor("");
        break;
      case page.startsWith("/Contact"):
        setBackgroundColor("#1C1D20");
        setTextColor("#FFFFFF");
        break;
      case page === "/":
        setBackgroundColor("");
        setTextColor(isMobile ? "#FFFF":"");
        break;
      case page.startsWith("/projects"):
        setBackgroundColor("#FFFFFF"); 
        setTextColor("#000000");
        break;
      case /^\/Projects\/detail\/\d+$/.test(page): 
        setBackgroundColor("#E5E4E2");
        setTextColor("#000000");
        break;
      case /^\/images_scrool\/detail\/\d+\/\d+$/.test(page):
        setBackgroundColor("#E5E4E2");
        setTextColor("#000000");
        break;
      case page === "/notfound": 
      case page === "*": 
        setBackgroundColor("#FFFFFF"); 
        setTextColor("#000000"); 
        break;
      default:
        setBackgroundColor("#FFFFF");
        setTextColor("");
        break;
    }
    localStorage.setItem("backgroundColor", backgroundColor);
  };
  useEffect(() => {
    localStorage.setItem("backgroundColor", backgroundColor);
  }, [backgroundColor]);
  return (
    <div
      className="flex justify-between w-full text-black  md:p-5"
      style={{ backgroundColor, color: textColor }}
    >
      <div className="mt-10    flex justify-between items-center w-full md:ml-10  ml-2">
        <div>
          <span className="sr-only">main name</span>
          <Magnetic>
            <motion.h1 className="font-telma text-3xl font-extrabold cursor-pointer ">
              <Link to="/" onClick={() => handleNavigation("/")}>
                <HoverNameChange></HoverNameChange>
              </Link>
            </motion.h1>
          </Magnetic>
        </div>
        <div className="flex-grow"></div>
        <div>
          <span className="sr-only"> nav list</span>
          <Navbar1 />
          <div
            className=" font-lato-thin  font-semibold	  gap-3 justify-end md:gap-16 text-xl  mr-10 text-black md:flex hidden"
            style={{ color: textColor }}
          >
            <p
              className=" cursor-pointer"
              onClick={() => handleNavigation("/about")}
            >
              <Link to="/about">{currentLanguage.about}</Link>
            </p>
            <p
              className=" cursor-pointer"
              onClick={() => handleNavigation("/Contact")}
            >
              <Link to="/Contact">{currentLanguage.contact}</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
