// src/ReviewForm.js

import React, { useState } from 'react';
import axios from 'axios';

function ReviewForm() {
  const [review, setReview] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null); // Reset any previous errors
    try {
      const result = await axios.post("http://127.0.0.1:5000/values", { review });
      setResponse(result.data);
    } catch (error) {
      setError("Something went wrong with the request");
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Sentiment Review App</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="review">Enter your review: </label>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            required
            rows="4"
            cols="50"
          ></textarea>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit Review"}
        </button>
      </form>

      {error && <div style={{ color: 'red' }}>{error}</div>}

      {response && (
        <div>
          <h2>Result:</h2>
          <p><strong>Review:</strong> {response.review}</p>
          {response.result && response.result.length > 0 ? (
            <div>
              <p><strong>Sentiment:</strong> {response.result[0].label}</p>
              <p><strong>Score:</strong> {response.result[0].score}</p>
              <p><strong>Advanced Feedback:</strong> {response.advanced_feedback}</p>
            </div>
          ) : (
            <p>No sentiment result returned</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ReviewForm;
